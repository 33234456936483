<template>
  <div class="pbf">
    <template v-if="source_data?.id">
      <BrandHead
        :id="source_data.id"
        :brand_package="source_data.brand_package"
        :nm="source_data.name"
        :nmalt="source_data.name"
        :logo="source_data.logo_m"
        :link="source_data.section"
        :rate="source_data.item_stat?.avg_rate"
        :slogan="source_data.slogan"
        :cover="source_data.cover"
        :background="source_data.background"
        :cover_mobile="source_data.cover_mobile"
        :cnt_followers="source_data.item_stat?.cnt_followers"
        :isfollow="source_data?.addon?.follow"
        :type="type"
        :offical_user="source_data.offical_user"
        :bread="[
          { name: $t('category_' + source_data.category), link: linkToCategory},
          { name: source_data.name, link: source_data.section }
        ]"
        @follow="follow(1)"
        @unfollow="follow(0)"
      >
        <UiTabs
          :tabs="getTabs"
          :acttab="active_tab"
        />
      </BrandHead>

      <div class="pb grid_width">
        <NuxtPage
          :data="source_data"
          :id="source_data.id"
        />
      </div>

      <BrandContextMenu
        :is-follow="source_data?.addon?.follow"
        @follow="follow(1)"
        @unfollow="follow(0)"
      />
    </template>
    <template v-else>
      <SkBrandOpened />
    </template>
  </div>
</template>

<script setup lang="ts">
const route = useRoute();
const router = useRouter();
const { $api, $tagsUtil, $ga, $follow, $popup } = useNuxtApp()
const id = ref(route.params['id']);
const active_tab = ref('info');

const props = defineProps<{categoryName?: string}>()

const getTabs = computed(() => {
  let items = [];

  items.push({
    id: 'info',
    name: 'Info',
    link: source_data.value.link + '',
    count: 0,
    icon: '',
  });

  if(source_data.value?.item_stat?.cnt_reports){
    items.push({
      id: 'diaries',
      name: 'Diaries',
      link: source_data.value.link + '/diaries',
      count: source_data.value?.item_stat?.cnt_reports,
      icon: '',
    });
  }

  if(source_data.value?.item_stat?.cnt_growers){
    items.push({
      id: 'growers',
      name: 'Growers',
      link: source_data.value.link + '/growers',
      count: source_data.value?.item_stat?.cnt_growers,
      icon: '',
    });
  }

  if(source_data.value?.item_stat?.cnt_items){
    items.push({
      id: 'products',
      name: 'Products',
      link: source_data.value.link + '/products',
      count: source_data.value?.item_stat?.cnt_items,
      icon: '',
    });
  }

  if(source_data.value?.item_stat?.cnt_reviews){
    items.push({
      id: 'reviews',
      name: 'Reviews',
      link: source_data.value.link + '/reviews',
      count: source_data.value?.item_stat?.cnt_reviews,
      icon: '',
    });
  }

  return items;
})

const follow = function(state) {
  $follow.toggle('brand', source_data.value.id, state, {
    type: 'brand',
    id: source_data.value.id,
    avatar: source_data.value.avatar_small,
    name: source_data.value.name,
    link: source_data.value.link,
  }).then((response) => {
      source_data.value.addon.follow = state;
      if(source_data.value.item_stat)
        source_data.value.item_stat.cnt_followers += state ? 1 : -1;
    }).catch((error) => {
      $popup.error('Error')
    });
}

const loadData = async (load_id) => {
  return await $api.getBrandBySectionCategory(load_id, props.categoryName)
}

const { pending: is_loading, data: source_data } = await useLazyAsyncData('source_data_' + id.value, async () => await loadData(id.value))

const currentPath = computed(() => route.path)

const linkToCategory = computed(() => {
  const pathSegments = currentPath.value.split('/')
  pathSegments.pop()
  return pathSegments.join('/') || '/'
})

watch( () => source_data, () => {
  useHead(useNuxtApp().$head.getBrandTent(source_data.value));
}, {immediate: true, deep: true})

watch( () => router.currentRoute, () => {
  useHead(useNuxtApp().$head.getBrandTent(source_data.value));
}, {immediate: true, deep: true})

onBeforeUnmount(() => {
  source_data.value = null;
})
</script>

<style scoped>
.add_filter_box {
  display: flex;
  margin-left: auto;
  align-self: baseline;
}

.flow_boxes {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
</style>